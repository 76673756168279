.supportTicketModal {
  .okButton {
    display: none;
  }
}

.label {
  margin: 1em 0;
  display: grid;
  grid-template-columns: 0.6fr 2.4fr;

  span {
    font-weight: 700;
    width: 80px;
    display: inline-block;
  }
}

.button {
  float: right;
}
