@import '../../sci-ui-components/styles/breakpoints';

.search {
  padding: 0;
  max-width: 672px;
  position: relative;
  font-size: var(--font-size-l);

  .searchIcon {
    color: var(--text-color-primary);
    margin: 4px;
    width: 16px;
    height: 16px;
  }

  .searchIsFocused {
    border: 1px solid var(--black);
  }

  :global(.ant-input-clear-icon) {
    color: var(--text-color-primary);
  }

  :global(.ant-input) {
    font-size: var(--font-size-l);
  }

  :global(.ant-input-affix-wrapper) {
    min-height: 60px;
    max-height: 60px;
    border-radius: 0;
    border-top-color: transparent;

    &:focus {
      border-color: var(--black);
      box-shadow: none;
    }
  }
  :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)):hover {
    border-color: unset;
  }

  :global(.ant-input-affix-wrapper-focused) {
    @extend .searchIsFocused;
  }

  :global(.ant-input-affix-wrapper):hover,
  .listHasItems {
    @extend .searchIsFocused;
  }
}

.searching {
  @include mdUp {
    height: 100%;
  }

  @include smDown {
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100vw;
    max-width: 100vw;
  }
}

.searchList {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  min-width: min(560px, 100vw);
  max-width: 672px;
  z-index: 100;
  overflow-x: auto;
  margin-bottom: 4px;
  background-color: var(--white);
  border: 1px solid var(--black);
  border-radius: 0 0 5px 5px;
  border-top: unset;
  box-shadow: var(--box-shadow-regular);
  display: flex;
  flex-direction: column;

  transition: height var(--transition-duration-snappy), opacity var(--transition-duration-snappy);
  height: 0;
  opacity: 0;

  @include smDown {
    max-width: 100vw;
  }

  &.open {
    height: 70vh;
    opacity: 1;
  }
}

.beforeBlock {
  flex: 0 0 auto;
}

$searchItemInitialSectionOffset: 128px;

.featuredCardsHeader {
  font-size: var(--font-size-s);
  color: var(--text-color-secondary);
  width: 100%;
  display: flex;
  padding: 0 10px;

  & > :nth-child(1) {
    flex: 1 1 auto;
    max-width: calc(100% - $searchItemInitialSectionOffset);
  }
  & > :nth-child(2) {
    flex: 0 0 auto;
    min-width: 75px;
  }
}

.containerDivider {
  font-size: var(--font-size-l);
  padding: 30px 10px 15px;
}

.list {
  flex: 1 1 auto;
  overflow: hidden;
}

.hasSelectedCards {
  border-top: unset;
}
.spinner {
  width: 100%;
  align-content: center;
}

.searchTypeButton {
  margin: 5px 10px;

  .activeSearchTypeButton {
    border-color: var(--teal);
    background-color: var(--teal);
    color: var(--white);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--teal);
      color: var(--white);
    }

    &.disabled {
      color: var(--white);

      &:hover,
      &:focus,
      &:active {
        color: var(--white);
      }
    }
  }
}

.bottomOfListContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
