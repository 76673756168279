.loadingIndicator {
  width: 100%;
  flex: 0 0 auto;
}

.menuButton {
  background-color: inherit;
  padding: 0;
  border: none;
}
