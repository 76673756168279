@import '../../../sci-ui-components/styles/breakpoints.scss';

.chartWrapper {
  margin: 16px 0;
}

.chartHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;

  @include md {
    gap: 20px;
  }

  @include smDown {
    flex-wrap: wrap;
    gap: 12px;
  }
}
