.forSaleItemTypeButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-bottom: 32px;

  .disabled {
    &:global(.ant-btn) {
      background-color: var(--white);
      color: var(--black);
      border-color: var(--black);
    }
  }

  .enabled {
    &:global(.ant-btn) {
      background-color: var(--granite-gray);
      color: var(--white);
      border-color: var(--granite-gray);
    }
  }
}
