@import '../../../styles/breakpoints';
@import '../../Search.module';

.searchItemWrapper {
  padding: 8px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;

  &:hover {
    background-color: var(--concrete-opaque);
  }

  .actionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .chartButton {
      margin: 0 14px;
    }

    .compareLabel {
      font-size: var(--font-size-s);
      color: var(--granite-gray);
    }
  }

  &.selected {
    background-color: var(--blue-grey-darker);

    &:hover {
      background-color: var(--concrete-opaque);
    }
  }
}

.clickable {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
}

$searchItemInitialSectionOffset: 128px;

.left {
  display: flex;
  flex: 1 1 auto;
  max-width: calc(100% - $searchItemInitialSectionOffset);
}

.image {
  min-width: 60px;

  & > img {
    max-height: 90px;
    max-width: 60px;
  }
}
.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8px;
  min-width: 0;

  .rookieIndicator {
    margin-top: 4px;
    margin-right: 4px;
    height: fit-content;
  }
  .popCount {
    margin-left: 4px;
    margin-top: 6px;
    flex: 0 0 auto;
    font-size: var(--font-size-s);
  }
}

.titleText {
  margin-bottom: 0;
  margin-right: 4px;
  white-space: nowrap;
}

.descriptionText {
  width: 100%;
  margin-bottom: 4px;
}

.right {
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  align-items: center;
  align-self: center;
  justify-content: space-between;

  .stats {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    margin-top: 12px;
    text-align: center;
    padding-right: 4px;

    .price {
      color: var(--black);
      font-family: var(--font-family-rubik);
      font-size: var(--font-size-xl);
      font-weight: var(--font-weight-medium);
      font-style: normal;
      letter-spacing: -0.32px;
      line-height: 18px;
      margin-bottom: 0;
    }
    .date {
      color: var(--granite-gray);
      font-size: var(--font-size-s);
    }
  }
}

.divider {
  height: 1px;
  background-color: var(--concrete-opaque);
  width: 90%;
  border: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.rawSaleItemContainer {
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;

  .rightSideContainer {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .detailsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 30px;

      .priceDetail {
        margin-right: 10px;
        color: var(--black);
        font-family: var(--font-family-rubik);
        font-size: var(--font-size-xl);
        font-weight: var(--font-weight-medium);
        font-style: normal;
        letter-spacing: -0.32px;
        margin-bottom: 0;
      }

      .dateDetail {
        color: var(--granite-gray);
        font-size: var(--font-size-xl);
        margin-bottom: 0;
        margin-right: 30px;
      }

      .platformLogo {
        height: 20px;
        width: auto;
      }
    }
  }
}
