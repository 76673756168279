@import '../../styles/breakpoints.scss';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.iconBox {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconBoxDashboard {
  box-shadow: var(--box-shadow-small);
  background-color: var(--white);
}

.iconBoxContext {
  background-color: transparent;
  border: none;
}

.icon {
  color: var(--gull-gray);
  width: 100%;
  height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin: 0;
  color: var(--gray);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}

.inLineLabel {
  margin-top: -10px;
  color: var(--gray);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-m);
}

.value {
  margin: 0;
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-regular);
  font-style: normal;
}

.additionalValue {
  font-size: var(--font-size-m);
  margin: 0;
}

.valuePositive {
  color: var(--atlantis);
}

.valueNegative {
  color: var(--monza);
}

.small {
  gap: 8px;

  .iconBox {
    height: 32px;
    width: 32px;

    @include lgDown {
      height: 28px;
      width: 28px;
    }
  }

  .iconBoxDashboard {
    padding: 6px;
  }

  .iconBoxContext {
    padding: 2px;
  }

  .label {
    font-size: var(--font-size-m);
    line-height: 18px;

    @include lgDown {
      font-size: var(--font-size-s);
      line-height: 16px;
    }
  }

  .value {
    font-size: var(--font-size-xxl);
    line-height: 24px;

    @include lgDown {
      font-size: var(--font-size-xl);
      line-height: 24px;
    }
  }
}

.medium {
  gap: 8px;

  .iconBox {
    height: 40px;
    width: 40px;

    @include lgDown {
      height: 32px;
      width: 32px;
    }
  }

  .iconBoxDashboard {
    padding: 8px;
  }

  .iconBoxContext {
    padding: 2px;
  }

  .label {
    font-size: 0.9rem;
    line-height: 1.2;
  }

  .value {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}

.large {
  gap: 8px;

  .iconBox {
    height: 54px;
    width: 54px;

    @include lgDown {
      height: 44px;
      width: 44px;
    }
  }

  .iconBoxDashboard {
    padding: 10px;
  }

  .iconBoxContext {
    padding: 4px;
  }

  .label {
    font-size: var(--font-size-xxl);
    line-height: 28px;

    @include lgDown {
      font-size: var(--font-size-xl);
      line-height: 24px;
    }
  }

  .value {
    font-size: var(--font-size-xxxxl);
    line-height: 32px;

    @include lgDown {
      font-size: var(--font-size-xxxl);
      line-height: 28px;
    }
  }
}
