@import '../../styles/breakpoints.scss';

.root {
  display: flex;
  background-color: var(--blue-dark);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
  color: var(--concrete);

  @include mdUp {
    padding: 8px 32px;
    align-items: center;
  }

  @include smUp {
    gap: 12px;
  }

  @include smDown {
    padding: 8px 20px;
    flex-direction: column;
  }

  @include xs {
    gap: 8px;
  }
}

.header {
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  @include smUp {
    align-items: center;
    gap: 12px;
  }

  @include xs {
    flex-direction: column;
    gap: 8px;
  }
}

.stat {
  margin: 0;
  padding: 0;
}

.statText {
  margin: 0;
}

.value {
  font-weight: var(--font-weight-bold);
}
