@import '../../../../../sci-ui-components/styles/breakpoints.scss';

.root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: stretch;
}

.warning {
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-image: linear-gradient(55deg, rgb(255 165 0 / 50%), rgb(255 0 0 / 70%)) 1;
  border-width: 8px;
  border-style: solid;
}

.desktopSideNavMenu {
  flex: 0 0 auto;

  @include smDown {
    display: none;
  }
}

.page {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: var(--concrete);

  // A fix for Safari where dashboard elements would flicker when slightly exiting the view while having a list in the infobar active.
  z-index: 1;
}

.header {
  flex: 0 0 auto;
}

.infoBar {
  flex: 0 0 auto;
}

.content {
  flex: 1 0 auto;
}

.stickyHeader {
  .content {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}

.appBadges {
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    padding: 1rem;
  }
}
