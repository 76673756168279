.currencyHeader {
  margin-top: 4px;
  margin-bottom: 20px;
}

.currencyDropdownContainer {
  display: flex;
  flex-direction: row;
}

.currencyDropdown {
  min-width: 90px;
  margin-left: 20px;
  :global {
    .ant-select-arrow {
      top: 40% !important;
    }
  }
}

.currencyOption {
  height: 30px;
}

.currencyDisclaimer {
  font-size: 12px;
  color: var(--sonic-silver);
  font-style: italic;
}
