.root {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.title {
  flex: 1 1 auto;
}

.button {
  flex: 0 0 auto;
  margin-right: -18px;
  height: 40px !important;
}

.affiliateText {
  font-size: var(--font-size-s);
  color: var(--gray);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-rubik);
  text-transform: none;
  margin-bottom: 12px;
  margin-top: -12px;
}

.list {
  gap: 8px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.image {
  width: 52px;
  flex: 0 0 auto;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.detailsContainer {
  gap: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sellerInfo {
  color: var(--sonic-silver);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.itemTitle {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
  text-decoration: underline;
  margin: 0;
}

.price {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-medium);
  margin: 0;
  text-align: end;
}

.divider {
  &:global(.ant-divider-horizontal) {
    margin: 16px 0;
  }
}

.auctionButton {
  &:global(.ant-btn) {
    width: 180px !important;
    min-width: 0px !important;
  }
}

.auctionButtonsContainer {
  margin-bottom: 20px;
  justify-content: space-around;
}
