.modalDialogWidth {
  width: 880px !important;
  text-align: center;

  .mainText {
    font-size: var(--font-size-xxxl);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .amountText {
    font-size: var(--font-size-xxxxl);
    font-weight: var(--font-weight-bolder);
    margin-bottom: -10px;
  }

  .couponText {
    font-size: var(--font-size-xxxl);
    margin-bottom: 20px;
    b {
      font-weight: var(--font-weight-bold);
    }
  }
}
