.indicator {
  width: 8px;
  height: 100%;
}

.positive {
  background-color: var(--success);
}

.neutral {
  background-color: var(--gull-gray);
}

.negative {
  background-color: var(--monza);
}

.positiveNeutralNegative {
  background: linear-gradient(
    180deg,
    var(--success) 0%,
    var(--success) 33%,
    var(--gull-gray) 33%,
    var(--gull-gray) 66%,
    var(--monza) 66%,
    var(--monza) 100%
  );
}

.positiveNeutral {
  background: linear-gradient(
    180deg,
    var(--success) 0%,
    var(--success) 50%,
    var(--gull-gray) 50%,
    var(--gull-gray) 100%
  );
}

.positiveNegative {
  background: linear-gradient(180deg, var(--success) 0%, var(--success) 50%, var(--monza) 50%, var(--monza) 100%);
}

.neutralNegative {
  background: linear-gradient(180deg, var(--gull-gray) 0%, var(--gull-gray) 50%, var(--monza) 50%, var(--monza) 100%);
}
