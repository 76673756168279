.root {
  position: relative;
}

.button {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--white-translucent);
  transition: opacity var(--transition-duration-medium);
  opacity: 0;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.icon {
  width: 40px;
  height: 40px;
  max-width: 80%;
  max-height: 80%;
  color: var(--black);
}

.largeImage {
  margin-top: -36px;
}
