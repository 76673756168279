@import '../../styles/breakpoints.scss';

.root {
  background-color: var(--blue-hover);
  position: relative;
  display: flex;
  align-items: center;
}

.button {
  position: absolute;
}

.buttonPrev {
  left: 0;
}

.buttonNext {
  right: 0;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  scroll-snap-type: x mandatory;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeCritical {
  background-color: var(--monza);
  color: var(--white);
}

.typeInfo {
  background-color: var(--blue-hover);
  color: var(--white);
}

.typeWarning {
  background-color: var(--warning);
  color: var(--black);
}

.listItem {
  scroll-snap-align: start;
  margin: 0;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  align-items: center;

  @include mdUp {
    padding: 8px 32px;
  }

  @include smDown {
    padding: 8px 20px;
  }
}

.notification {
  overflow: auto;
  max-height: 100%;
}

.notificationText {
  margin: 0;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-regular);
}
