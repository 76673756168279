@import '../styles/breakpoints.scss';

.root {
  display: flex;
  align-items: stretch;
  overflow: hidden;

  @include mdUp {
    height: 52px;
  }

  @include smDown {
    flex-direction: column;
  }
}

.notifications {
  overflow: hidden;
  flex: 1 1 auto;

  @include mdUp {
    flex: 1 1 70%;
  }
}

.stats {
  overflow: hidden;
  flex: 0 1 auto;

  @include lgUp {
    flex: 0 0 auto;
  }
}
