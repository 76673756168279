.mainContainer {
  margin: 4px 10px;
  display: inline-block;

  .highlightTerm {
    color: var(--brand-primary);
  }

  .smallLabel {
    font-size: 10px;
    color: var(--dark-grey);
  }

  .interactiveLabel {
    cursor: pointer;
    color: var(--blue-ribbon);

    .backIcon {
      margin-right: 2px;
    }
  }

  .suggestion {
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
    font-style: italic;
    color: var(--dark-grey);
    text-transform: capitalize;

    &:hover {
      background-color: var(--blue-grey-darker);
    }

    .useIcon {
      margin-right: 10px;
    }
  }
}
