.root {
  display: inline-flex;
  flex-wrap: wrap;
}

.chip {
  border: 1px solid var(--celeste);
  border-radius: 4px;
  display: inline-flex;
}

.categoryText {
  flex: 1 1 auto;
  border-radius: inherit;
  padding: 3px 8px;
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: center;
  white-space: nowrap;
}
