@import '../../../sci-ui-components/styles/breakpoints.scss';

.root {
  display: flex;
  overflow: hidden;
  background-color: var(--concrete);
  padding: 12px 36px;
  align-items: center;
  gap: 16px;
  color: var(--black);

  &:hover {
    color: var(--black);
  }

  @include mdDown {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4px 20px;
    gap: 4px;
  }
}

.label {
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-medium);
  color: var(--text-color-primary);
  text-transform: uppercase;

  @include md {
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
  }

  @include smDown {
    display: none;
  }
}

.selection {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mdDown {
    flex: 0 0 auto;
  }
}

.value {
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-regular);
  color: var(--text-color-primary);

  @include mdDown {
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
  }
}

.icon {
  margin-left: 16px;
}

.menu {
  :global(.ant-dropdown-menu-item) {
    padding: 8px 16px;
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    font-weight: var(--font-weight-regular);
    color: var(--text-color-primary);
  }
}
