.root {
  position: relative;
}

.header {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  flex: 1 1 auto;
}

.button {
  flex: 0 0 auto;
  margin-right: -18px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.listHeader {
  margin-bottom: 8px;
  color: var(--sonic-silver);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  font-weight: var(--font-weight-regular);
}
.columnHeaders {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.list {
  gap: 8px;
}

.item {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-regular);

  .amount {
    font-weight: var(--font-weight-medium);
  }

  .numberOfSales {
    text-align: center;
    padding-left: 10%;
  }
}

.date {
  flex: 1 1 50%;
  text-align: center;
}

.amount {
  flex: 1 1 50%;
  text-align: end;
}

.divider {
  &:global(.ant-divider-horizontal) {
    margin: 16px 0;
  }
}
