@import '../../sci-ui-components/styles/breakpoints';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: var(--concrete);
  gap: 0px;
  padding: 0 8px 12px 8px;

  @include xs {
    flex-direction: column;
    gap: 12px;
  }
}

.slider {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow-x: hidden;
}
.sliderItems {
  gap: 10px;
  padding-top: 10px;
}

.emptyCard {
  border: 1px solid white;
  height: 84px;
  background-color: white;
  min-width: 60px;
}

.chart {
  height: 100%;
  position: relative;
  min-width: 60px;
  .closeIcon {
    color: gray;
    padding: 0px;
    border: unset;
    position: absolute;
    right: -15px;
    top: -15px;

    & > svg {
      border-radius: 50%;
      background-color: var(--white);
    }
  }
  .image {
    max-width: 60px;
    max-height: 100px;
  }
  .grade {
    width: 60px;
  }

  .gradeText {
    padding: 3px;
    white-space: normal;
  }
}

.actions {
  flex: 0 0 112px;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  @include xs {
    flex: 0 0 auto;
    flex-direction: row;
    align-self: stretch;
    justify-content: stretch;
    gap: 4px;

    & > * {
      flex: 1 1 50%;
    }
  }
}
