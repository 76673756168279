.dateSelector {
  border-color: var(--celeste);

  border-radius: var(--border-radius);
  align-items: center;
  height: 40px;

  input {
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }
  :global(.ant-picker-suffix) {
    color: var(--gull-gray);
  }
}
