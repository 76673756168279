@import '../../../sci-ui-components/styles/breakpoints.scss';

.helpMenuOverlay {
  border: 2px solid var(--quick-silver);
  border-radius: var(--border-radius);
  padding: 16px;
  .helpMenuItem {
    justify-content: center;
    display: inline-flex;
    width: 100%;
    svg {
      color: var(--granite-gray);
      margin-right: 16px;
      margin-top: 4px;
    }
    .helpMenuItemLabel {
      border-bottom: 1px solid var(--quick-silver);
      color: var(--black);
      display: block;
      padding-bottom: 8px;
      padding-right: 16px;
      width: 100%;
    }
  }
  :global(.ant-dropdown-menu-item:last-of-type) .helpMenuItemLabel {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.helpMenu {
  :global(.ant-dropdown-trigger) {
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;

    @include smUp {
      border: 2px solid var(--quick-silver);
      border-radius: 24px;
      color: var(--quick-silver);
      padding: 6px 12px;
    }
  }
  :global(.ant-dropdown-trigger.ant-dropdown-open) {
    border-bottom-right-radius: 0;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;

  @include xs {
    display: none;
  }
}

.mobileLink {
  @include smUp {
    display: none;
  }
}
