.modalContainer {
  padding-bottom: 0;
}

.detailsContainer {
  margin-bottom: 10px;

  .image {
    max-width: 200px;
    min-width: 200px;
    max-height: 300px;
  }

  .statRow {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-start;

    .statBox {
      width: 160px;
      height: 40px;
      align-items: center;

      .logoLabel {
        align-items: flex-start;

        img {
          height: 23px;
          width: auto;
        }
      }
    }
  }
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
