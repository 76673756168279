@import '../styles/breakpoints.scss';

.root {
  color: var(--text-color-primary);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  line-height: var(--line-height-xxxl);
  font-weight: var(--font-weight-bolder);
  font-style: normal;
  text-transform: uppercase;

  @include smDown {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
  }
}
